<template>
  <div>
    <div class="man-title">
      <span>维修记录</span>
    </div>
    <div class="search">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="设备类型">
          <el-select size="small" v-model="form.deviceTypeId" clearable placeholder="">
            <el-option
              v-for="item in deviceTypeList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在位置">
          <el-select size="small" v-model="form.facilityId" clearable placeholder="">
            <el-option
              v-for="item in weizhi"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡视点">
          <el-select size="small" v-model="form.patrolId" clearable placeholder="">
            <el-option
              v-for="item in xunshidian"
              :label="item.patrolName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-select size="small" v-model="form.taskType" clearable placeholder="">
            <el-option label="巡检计划" :value="0"></el-option>
            <el-option label="点检计划" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onSubmit"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%">
       <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
        <el-table-column prop="patrolNumber" label="巡视点编号"></el-table-column>
        <el-table-column prop="deviceName" label="设备名称"></el-table-column>
        <el-table-column prop="alias" label="设备别名"></el-table-column>
        <el-table-column prop="deviceStatus" label="设备状态">
          <template>
            <span style="color: red">维修中</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
        <el-table-column prop="facilityName" label="设施位置"></el-table-column>
        <el-table-column prop="specification" label="规格参数"></el-table-column>
        <el-table-column prop="taskType" label="任务类型">
          <template slot-scope="scope">
            {{ scope.row.taskType == 0 ? '巡检' : '点检' }}
          </template>
        </el-table-column>
        <el-table-column prop="repairTime" label="维修时间">
          <template slot-scope="scope">
            {{ scope.row.repairTime ? dayjs(scope.row.repairTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-dropdown placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                <el-dropdown-item><div @click="recordOperation(scope.row, 2)">维修完成</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <page :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      size: 50,
      page: 1,
      total: 0,
      weizhi: [],
      xunshidian: [],
      form: {},
      tableData: [],
      deviceTypeList: []
    }
  },
  methods: {
    dayjs,
    recordOperation(row, operation) {
      this.$ajax.post('recordOperation', {
        operation: operation,
        patrolDeviceId: row.patrolDeviceId,
        taskDeviceId: row.taskDeviceId
      }).then((res) => {
        this.$message.success("成功")
        this.loadListData()
      });
    },
		details(row) {
      this.$router.push({ name: 'recordRepairDetail', params: {row: row}})
    },
    load_weizhi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.weizhi = res.data;
      });
    },
    load_xunshidian() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.xunshidian = res.data;
      });
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.deviceTypeList = res.data;
      });
    },
    onSubmit() {
      this.page = 1
      this.loadListData()
    },
    pageChange(val) {
      this.page = val
      this.loadListData()
    },
    loadListData() {
      this.$ajax.post('recordRepair', this.form, {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
      })
    }
  },
  mounted() {
    this.loadListData()
    this.load_weizhi()
    this.load_xunshidian()
    this.load_leixing()
  }
}
</script>

<style lang="less" scoped>

</style>